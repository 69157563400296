@layer components {
	.loading {
		.submit-label {
			@apply opacity-0;
		}

		.submit-icon {
			@apply opacity-0;
		}

		.download-icon {
			@apply hidden;
		}

		.spinner {
			@apply inline-block;
		}
	}

	.spinner-border {
		animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: #0060ac transparent transparent transparent;

		&:nth-child(1) {
			animation-delay: -0.45s;
		}

		&:nth-child(2) {
			animation-delay: -0.3s;
		}

		&:nth-child(3) {
			animation-delay: -0.15s;
		}
	}

	.spinner-border-white {
		border-color: #fff transparent transparent transparent;
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
