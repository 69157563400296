/**
	* This loads TailwindCSS and it's plugins, in combination with our own scss, defined in the folders
	* mixins, utilities, and components
	*
	* They are globally imported, so add a file to the folder, and the import happens automatically
	* thanks to import-glob-loader.
	*
	* If you need a file to NOT be included, rename it to ie. *.scss.not or something else, not ending on .scss
	*
	* The loading order of elements is IMPORTANT. You would always want to load things in this order:
	*
	* Preflight is the base of everything, and we want it overridable in all other css.
	* Mixins, functions for generating css. They should NEVER output css themselves.
	* Components, either from Tailwind or custom css. We want these to be overridable by utilities
	* Utilities
	* Optional custom css
	*
	* HAPPY CODING ✌
*/

@import "tailwindcss/base";
@import "tailwindcss/components";
@import-glob "components/*.scss";

@import "tailwindcss/utilities";
@import-glob "utilities/*.scss";

@import "./../node_modules/tiny-slider/dist/tiny-slider.css";





[x-cloak] {
	display:none !important;
}

.custom-thumb::-webkit-slider-thumb {
	pointer-events: all;
	width: 24px;
	height: 24px;
	-webkit-appearance: none;
}

.box {
	transition: 0.5s;
	-webkit-transition: 0.9s;
}

.box-small:hover {
	border: 1px solid rgba(0,160,80,0);
}

.box-medium:hover {
	border: 3px solid rgba(0,160,80,0);
}

.box-small-white:hover {
	border: 1px solid rgba(0,160,80,0);
}

.box-medium-white:hover {
	border: 3px solid rgba(0,160,80,0);
}

.box::before, .box::after {
	width: 100%;
	height: 100%;
	z-index: 3;
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	box-sizing: border-box;
	-webkit-transform: scale(0);
	transition: 0.5s;
}

.curmudgeon-white::after {
	display: block;
	background: #fff;
	content: '';
	top: 100%;
	width: 0;
	-webkit-transform: scale(1);
}

.curmudgeon-black::after {
	display: block;
	background: #2B2523;
	content: '';
	top: 100%;
	width: 0;
	-webkit-transform: scale(1);
}

.curmudgeon-small::after {
	height: 1px;
}

.curmudgeon-medium::after {
	height: 3px;
}

.curmudgeon-white:hover::after, .curmudgeon-black:hover::after {
	width: 100%;
}
